import { Injectable } from '@angular/core';
import { BaseModelKeys, Address, AddressModelKeys } from '@ag-common-lib/public-api';
import { BehaviorSubject, Observable, combineLatest, firstValueFrom, map, shareReplay } from 'rxjs';
import { set } from 'lodash';
import { getAddressKeyExpression } from 'ag-common-svc/lib/utils/address.util';
import { compareAddressesIsDifferent } from 'ag-common-svc/shared/utils/full-address';

@Injectable()
export class SelectAddressService {
  private _agentAddresses$ = new BehaviorSubject<Address[]>([]);
  agentAddressesDataSource$ = this._agentAddresses$.pipe(
    map(associations => {
      const items = associations?.map(association => association);

      items.push({} as Address);

      return items;
    }),
    shareReplay(1),
  );

  readonly formData$ = new BehaviorSubject<Partial<Address>>(new Address());
  readonly keyExpr$ = new BehaviorSubject<string | ((association: Partial<Address>) => string)>(BaseModelKeys.dbId);

  selectedAddressKeys$: Observable<string[]> = combineLatest({
    agentAddressesDataSource: this.agentAddressesDataSource$,
    formData: this.formData$,
  }).pipe(
    map(({ agentAddressesDataSource, formData }) => {
      const selectedAddressKeys = agentAddressesDataSource
        ?.map(address => getAddressKeyExpression(address))
        ?.filter(key => {
          return key === getAddressKeyExpression(formData);
        });

      if (!selectedAddressKeys?.length) {
        return [null];
      }

      return selectedAddressKeys;
    }),
  );

  constructor() {}

  setFormData = (address: Partial<Address>) => {
    this.formData$.next(address);
  };

  setAddresses = (addresses: Address[]) => {
    if (!Array.isArray(addresses)) {
      return;
    }

    this._agentAddresses$.next(addresses);
  };

  updateAddress = (address: Partial<Address>, onAddressChanged) => {
    const formData = this.formData$.value;
    if (getAddressKeyExpression(address) === getAddressKeyExpression(formData)) {
      return null;
    }

    Object.keys(formData).forEach(key => {
      formData[key] = null;
    });

    Object.entries(address).forEach(([key, value]) => {
      formData[key] = value;
    });

    onAddressChanged && onAddressChanged(formData);
  };

  addressUniqValidation = async (): Promise<boolean> => {
    const addresses = this._agentAddresses$.value;
    const editedAddress = this.formData$.value;

    return addresses.every(address => {
      if (address === editedAddress) {
        return true;
      }

      const isDiffer = compareAddressesIsDifferent(address, editedAddress);

      return isDiffer;
    });
  };
}
