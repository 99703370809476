import { Inject, Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityApplications, RoleEntityPermissionMap, UserRole, UserRoleModelKeys } from 'ag-common-lib/public-api';
import { LoggerService } from 'ag-common-svc/lib/services/logger.service';
import { UserPermissionService } from 'ag-common-svc/lib/services/user-permissions.service';
import { AgentService, AuthService, FIREBASE_APP } from 'ag-common-svc/public-api';
import { FirebaseApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { UserRolesService } from 'ag-common-svc/lib/services/user-roles.service';
import { AuthLogService } from 'ag-common-svc/lib/services/auth-log.service';
import { AppRoutes } from 'src/app/app.model';
import { AgentsRoutes } from 'src/app/ag-crm/agents/agents.model';

@Injectable()
export class ARMAuthService extends AuthService {
  readonly armUserRoles$: Observable<UserRole[]>;
  readonly armPermissions$: Observable<RoleEntityPermissionMap>;

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,

    private toastrService: ToastrService,

    ngZone: NgZone,
    router: Router,
    route: ActivatedRoute,
    agentService: AgentService,
    loggerService: LoggerService,
    userPermissionService: UserPermissionService,
    userRolesService: UserRolesService,
    authLogService: AuthLogService,
  ) {
    super(
      fireBaseApp,
      ngZone,
      router,
      route,
      toastrService,
      agentService,
      loggerService,
      userPermissionService,
      userRolesService,
      authLogService,
    );
    this.afterLogoutRedirectPath = 'login-form';
    this.homePathCommands = [AppRoutes.Agents, AgentsRoutes.AgentsList];

    this.auth = getAuth(fireBaseApp);

    this.armUserRoles$ = this.userRoles$.pipe(
      map((userRoles: UserRole[]) => {
        return userRoles.filter(
          role => role[UserRoleModelKeys.isEnable] && role[UserRoleModelKeys.appId] === EntityApplications.crm,
        );
      }),
      shareReplay(1),
    );

    this.armPermissions$ = this.armUserRoles$.pipe(
      map((userRoles: UserRole[]) => {
        // Initialize an empty object to store permissions
        const permissionsMap: RoleEntityPermissionMap = {};

        // Iterate over user roles
        userRoles.forEach(userRole => {
          // Get the activity permissions from userRole
          const activityPermissions = userRole[UserRoleModelKeys.activity] ?? {};

          // Iterate over activity permissions
          Object.keys(activityPermissions).forEach(entityKey => {
            // Check if entity key exists in permissionsMap, if not, initialize it
            if (!permissionsMap[entityKey]) {
              permissionsMap[entityKey] = {};
            }

            // Add or update permission values for each entity
            Object.keys(activityPermissions[entityKey]).forEach(permissionKey => {
              // If permission was already set to true, keep it as true otherwise use the current value
              permissionsMap[entityKey][permissionKey] =
                permissionsMap[entityKey][permissionKey] === true || activityPermissions[entityKey][permissionKey];
            });
          });
        });

        return permissionsMap;
      }),
      shareReplay(1),
    );
  }

  public async signInWithEmailAndPassword(email: string, password: string) {
    const agent = await super.signInWithEmailAndPassword(email, password);

    if (!agent) {
      return;
    }

    const hasPermissions = await this.checkARMPermissions();

    if (!hasPermissions) {
      const errorCode = await this.logMessage('LOGIN', agent?.p_email, 'Agent have no User Permissions to access ARM.');
      this.toastrService.error(
        `<br/> <p>Permissions to Access ARM has not been granted.</p><p>Please contact Alliance Group for Assistance with this code: ${errorCode}</p>`,
        'Access Denied',
        {
          enableHtml: true,
          disableTimeOut: true,
        },
      );

      return;
    }

    await this.logUserIntoPortal(agent);

    await this.navigateForward();
  }

  public checkARMPermissions = async (): Promise<boolean> => {
    const userRoles = await firstValueFrom(this.userRoles$);
    if (!Array.isArray(userRoles) || !userRoles?.length) {
      return false;
    }

    return userRoles?.some(
      role => role[UserRoleModelKeys.isEnable] && role[UserRoleModelKeys.appId] === EntityApplications.crm,
    );
  };

  protected onAgentEmailNotVerified = async () => {
    this.toastrService.error(
      '<p>To continue You need to validate the provided Email Address. <br/> Use Agent Portal for Verification.</p>',
      'Email Not Verified',
      {
        enableHtml: true,
      },
    );
  };
}
