import { MappingProperty } from '@elastic/elasticsearch/lib/api/types';
import { BUSINESS_PERSONAL_TYPE } from '../../lists/business-personal-type.enum';
import { LookupId } from '../../lists/lookups.enum';
import { LookupKeys } from '../crm/lookup.model';
import { AddressValidationResponse } from './google-address-validation.model';
import { uniqueId } from 'lodash';

export enum AddressModelKeys {
  id = 'id',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  state = 'state',
  zip = 'zip',
  country = 'country',
  county = 'county',
  addressType = 'address_type',
  isPrimaryShipping = 'is_primary_shipping',
  isPrimaryBilling = 'is_primary_billing',
  isPhysicalLocation = 'is_physical_location',

  placeId = 'place_id',
  lat = 'lat',
  lng = 'lng',
  validationResponse = 'validation_response',
}

export const AddressModelMap = {
  [AddressModelKeys.address1]: 'Address 1',
  [AddressModelKeys.address2]: 'Address 2',
  [AddressModelKeys.county]: 'County',
  [AddressModelKeys.country]: 'Country',
  [AddressModelKeys.city]: 'City',
  [AddressModelKeys.state]: 'State',
  [AddressModelKeys.zip]: 'Zip Code',
  [AddressModelKeys.addressType]: 'Address Type',
  [AddressModelKeys.isPrimaryShipping]: 'Is Shipping',
  [AddressModelKeys.isPrimaryBilling]: 'Is Billing',
  [AddressModelKeys.isPhysicalLocation]: 'Is Physical Location',
};

export class Address {
  [AddressModelKeys.id]?: string = uniqueId();
  [AddressModelKeys.placeId]?: string;
  [AddressModelKeys.lat]?: number;
  [AddressModelKeys.lng]?: number;
  [AddressModelKeys.address1]?: string;
  [AddressModelKeys.address2]?: string;
  [AddressModelKeys.city]?: string;
  [AddressModelKeys.state]?: string;
  [AddressModelKeys.zip]?: string;
  [AddressModelKeys.country]?: string;
  [AddressModelKeys.county]?: string;
  [AddressModelKeys.addressType]?: BUSINESS_PERSONAL_TYPE | LookupId<'AgencyAddressTypes'>;
  [AddressModelKeys.isPrimaryShipping]?: boolean = false;
  [AddressModelKeys.isPrimaryBilling]?: boolean = false;
  // isPhysicalLocation - This is mean to capture whether the Business is a physical storefront or not
  [AddressModelKeys.isPhysicalLocation]?: boolean = false;
  [AddressModelKeys.validationResponse]?: AddressValidationResponse;
}

export const AddressKeyMapping: {
  [key: string]: MappingProperty;
} = {
  [AddressModelKeys.address1]: { type: 'keyword' },
  [AddressModelKeys.address2]: { type: 'keyword' },
  [AddressModelKeys.city]: { type: 'text' },
  [AddressModelKeys.state]: { type: 'text' },
  [AddressModelKeys.zip]: { type: 'text' },
  [AddressModelKeys.country]: { type: 'text' },
  [AddressModelKeys.county]: { type: 'text' },
  [AddressModelKeys.addressType]: { type: 'text' },
  [AddressModelKeys.isPrimaryShipping]: { type: 'boolean' },
  [AddressModelKeys.isPrimaryBilling]: { type: 'boolean' },
  [AddressModelKeys.isPhysicalLocation]: { type: 'boolean' },
};

export const ADDRESS_LOOKUP = [
  {
    [LookupKeys.value]: AddressModelKeys.address1,
    [LookupKeys.description]: 'Address 1',
  },
  {
    [LookupKeys.value]: AddressModelKeys.address2,
    [LookupKeys.description]: 'Address 2',
  },
  {
    [LookupKeys.value]: AddressModelKeys.city,
    [LookupKeys.description]: 'City',
  },
  {
    [LookupKeys.value]: AddressModelKeys.state,
    [LookupKeys.description]: 'State',
  },
  {
    [LookupKeys.value]: AddressModelKeys.zip,
    [LookupKeys.description]: 'Zip',
  },
  {
    [LookupKeys.value]: AddressModelKeys.country,
    [LookupKeys.description]: 'Country',
  },
  {
    [LookupKeys.value]: AddressModelKeys.county,
    [LookupKeys.description]: 'County',
  },
  {
    [LookupKeys.value]: AddressModelKeys.addressType,
    [LookupKeys.description]: 'Address Type',
  },
  {
    [LookupKeys.value]: AddressModelKeys.isPrimaryShipping,
    [LookupKeys.description]: 'Is Primary Shipping',
  },
  {
    [LookupKeys.value]: AddressModelKeys.isPrimaryBilling,
    [LookupKeys.description]: 'Is Primary Billing',
  },
  {
    [LookupKeys.value]: AddressModelKeys.isPhysicalLocation,
    [LookupKeys.description]: 'Is Physical Location',
  },
];
