import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { AgCrmModule } from './ag-crm/ag-crm.module';
import { LayoutsModule } from './layouts/layouts.module';
import { ScreenService } from './shared/services/util/screen.service';
import { AppInfoService } from './shared/services/util/app-info.service';
import { RegistrationsModule } from './ag-crm/registrations/registrations.module';
import { DxFormModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { AdminCrmModule } from './admin-crm/admin-crm.module';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { AuthService, FIREBASE_APP, USE_CLOUD_FUNCTIONS_EMULATOR } from 'ag-common-svc/public-api';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import './date.prototype';
import { DatePipe } from '@angular/common';
import { IdleModule } from 'ag-common-svc/lib/components/idle/idle.module';
import { ARMAuthService } from './shared/services/util/arm-auth.service';
import { STRIPE_PUBLIC_KEY } from 'ag-common-svc/lib/components/ag-stripe-modal/ag-stripe-modal.models';
import { NgxStripeModule } from 'ngx-stripe';
import { ARMPermissionsService } from './shared/services/util/arm-permissions.service';
import { PermissionsService } from 'ag-common-svc/lib/services/permissions.service';
import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';
import { HttpClientModule } from '@angular/common/http';
import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_MAP_ID } from 'ag-common-lib/lib';

export const fireBaseApp: FirebaseApp = initializeApp(environment.common);

initializeFirestore(fireBaseApp, {
  experimentalForceLongPolling: true,
  ignoreUndefinedProperties: true,
  localCache: persistentLocalCache({
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    tabManager: persistentMultipleTabManager(),
  }),
});

@NgModule({
  declarations: [AppComponent, MainLayoutComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    AgCrmModule,
    AdminCrmModule,
    LayoutsModule,
    RegistrationsModule,
    DxFormModule,
    DxLoadIndicatorModule,
    IdleModule,
    NgxStripeModule.forRoot(environment.stripe_payments_public_key),
    HttpClientModule,
  ],
  providers: [
    ScreenService,
    AppInfoService,
    {
      provide: FIREBASE_APP,
      useValue: fireBaseApp,
    },
    {
      provide: USE_CLOUD_FUNCTIONS_EMULATOR,
      useValue: false,
    },
    {
      provide: AuthService,
      useClass: ARMAuthService,
    },
    {
      provide: ARMAuthService,
      useExisting: AuthService,
    },
    { provide: PermissionsService, useClass: ARMPermissionsService },
    {
      provide: STRIPE_PUBLIC_KEY,
      useValue: environment.stripe_payments_public_key,
    },
    {
      provide: GOOGLE_MAPS_API_KEY,
      useValue: environment.google.mapsAPIKey,
    },
    {
      provide: GOOGLE_MAPS_MAP_ID,
      useValue: environment.google.mapId,
    },
    ARMPermissionsService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
