import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { DataService } from './data.service';
import { FIREBASE_APP } from 'ag-common-svc/public-api';
import { DynamicListsGroup } from '@ag-common-lib/lib';

@Injectable()
export class DynamicListsGroupService extends DataService<DynamicListsGroup> {
  private readonly dynamicListsGroupServicesCollectionPath = 'dynamic-lists-group';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, DynamicListsGroupService.fromFirestore);
    super.setCollection(this.dynamicListsGroupServicesCollectionPath);
  }

  static readonly fromFirestore = (data): any => {
    console.log(data);
    return Object.assign({}, data);
  };
}
