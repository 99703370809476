import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { NotificationSchedulerEditModalComponent } from './notification-scheduler-edit-modal.component';
import { ModalWindowModule } from '../../../../modal-window/modal-window.module';
import { RecipientsDataSourcePipe } from './recipients-data-source.pipe';
import { HtmlEditorModule } from '../../../../html-editor/html-editor.module';
import { NotificationAddRecipientsModalModule } from '../../notification-add-recipients-modal/notification-add-recipients-modal.module';
import { DxDropDownButtonModule, DxFilterBuilderModule, DxTabPanelModule } from 'devextreme-angular';
import { NotificationNotificationChannelModalModule } from '../../notification-notification-channel-modal/notification-notification-channel-modal.module';
import { NotificationsChannelNamePipe } from './notifications-channel-name.pipe';
import { AddNotificationOptInGroupModalModule } from '../../add-notification-opt-in-group-modal/add-notification-opt-in-group-modal.module';
import { FilterGroupDisplayValuePipe } from './filter-builder-display-value.pipe';

@NgModule({
  declarations: [
    NotificationSchedulerEditModalComponent,
    RecipientsDataSourcePipe,
    NotificationsChannelNamePipe,
    FilterGroupDisplayValuePipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ModalWindowModule,
    HtmlEditorModule,
    NotificationAddRecipientsModalModule,
    DxTabPanelModule,
    DxDropDownButtonModule,
    NotificationNotificationChannelModalModule,
    AddNotificationOptInGroupModalModule,
    DxFilterBuilderModule,
  ],
    exports: [ NotificationSchedulerEditModalComponent, RecipientsDataSourcePipe, FilterGroupDisplayValuePipe ],
})
export class NotificationSchedulerEditModalModule {}
