import { ATTENDEE_TITLE_LIST, REGISTRANTS_TITLE_LIST } from '@ag-common-lib/public-api';
import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({ name: 'caption' })
export class CaptionPipe implements PipeTransform {
  transform(key: string, list: string): string | void {
    switch (list) {
      case 'attendeesList':
        const caption = get(ATTENDEE_TITLE_LIST, key);

        return caption;
    }
  }
}
