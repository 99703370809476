import { ActivityBase } from './activity-base.model';

export enum EmailLogKeys {
  title = 'title',
  sender = 'sender',
  date = 'date',
  time = 'time',
  to = 'to',
  cc = 'cc',
  bcc = 'bcc',
  externalContacts = 'externalContacts',
  detailsAgenda = 'detailsAgenda',
}

export enum EmailParticipantKeys {
  targetDbId = 'targetDbId',
  email = 'email'
}

export interface EmailParticipant {
  [EmailParticipantKeys.targetDbId]: string;
  [EmailParticipantKeys.email]: string;
}

export class EmailLog extends ActivityBase {
  [EmailLogKeys.title]: string;
  /**
   * Presented string is "targetDbId" and "email" presented as URLSearchParams string. Specifies the participant agent info. Because of restrictions of  the Firebase we not able to querying by objects in the list.
   */
  [EmailLogKeys.sender]: string;
  [EmailLogKeys.date]: Date;
  [EmailLogKeys.time]?: string;
  /**
   * Presented list is array of strings of "targetDbId" and "email" presented as URLSearchParams string. Specifies the participant agent info. Because of restrictions of  the Firebase we not able to querying by objects in the list.
   */
  [EmailLogKeys.to]?: string[];
  /**
   * Presented list is array of strings of "targetDbId" and "email" presented as URLSearchParams string. Specifies the participant agent info. Because of restrictions of  the Firebase we not able to querying by objects in the list.
   */
  [EmailLogKeys.cc]?: string[];
  /**
   * Presented list is array of strings of "targetDbId" and "email" presented as URLSearchParams string. Specifies the participant agent info. Because of restrictions of  the Firebase we not able to querying by objects in the list.
   */
  [EmailLogKeys.bcc]?: string[];
  [EmailLogKeys.externalContacts]?: string[];
  [EmailLogKeys.detailsAgenda]?: string;
}
