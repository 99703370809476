import { Pipe, PipeTransform } from '@angular/core';
import { AgentService } from 'ag-common-svc/public-api';

import { AgentGridFieldsService } from 'ag-common-svc/lib/services/agent-grid-fields/agent-grid-fields.service';
import { ConferenceAttendeeGridFieldsService } from 'ag-common-svc/lib/services/agent-grid-fields/conference-attendee-grid-fields.service';
import { DxFilterOperators, DxFilterOperatorsMap, FilterGroup, NotificationOptInGroupType } from '@ag-common-lib/lib';
import { ATTENDEE_TITLE_LIST } from '@ag-common-lib/public-api';
import { get } from 'lodash';

@Pipe({
  name: 'filterGroupDisplayValue',
})
export class FilterGroupDisplayValuePipe implements PipeTransform {
  constructor(
    private agentService: AgentService,
    private agentGridFieldsService: AgentGridFieldsService,
    private conferenceAttendeeGridFieldsService: ConferenceAttendeeGridFieldsService,
  ) {}

  transform(
    filterGroup: FilterGroup,
    type: NotificationOptInGroupType,
    normalizeType: 'text' | 'html' = 'text',
  ): Promise<string> {
    const captionsMap = this.getCaptionsMap(type);

    const convert = async (filterGroup: FilterGroup): Promise<string> => {
      const groupOperator = filterGroup?.groupOperator;
      const filters = filterGroup?.filters;

      if (groupOperator === DxFilterOperators.not) {
        const group = filters[0] as FilterGroup;
        const groupResult = await convert(group);
        return `NOT (${groupResult})`;
      }

      const items = await Promise.all(
        filters?.map(filter => {
          if (!('filters' in filter)) {
            const caption = get(captionsMap, filter.field, filter.field);
            const operator = DxFilterOperatorsMap.get(filter.operator) ?? filter.operator;

            return this.normalizeValue(type, filter.field, filter?.value).then(normalizedValue => {
              return normalizeType === 'text'
                ? `[${caption}] ${operator} '${normalizedValue}'`
                : `<span class="filter-item-field">${caption}</span> <span class="filter-item-operator">${operator}</span> <span class="filter-item-value">${normalizedValue}</span>`;
            });
          }
          return convert(filter);
        }),
      );

      return items?.join(` ${groupOperator} `);
    };
    return convert(filterGroup);
  }

  private normalizeValue(type: NotificationOptInGroupType, field: any, value: any): Promise<string> {
    if (type === NotificationOptInGroupType.agent) {
      return this.agentGridFieldsService.getDisplayValue(field, value);
    }
    if (type === NotificationOptInGroupType.registrant) {
      return this.conferenceAttendeeGridFieldsService.getDisplayValue(field, value);
    }

    return Promise.resolve(value);
  }

  private getCaptionsMap(type: NotificationOptInGroupType) {
    if (type === NotificationOptInGroupType.agent) {
      return this.agentGridFieldsService.fieldsCaptionMap;
    }
    if (type === NotificationOptInGroupType.registrant) {
      return ATTENDEE_TITLE_LIST;
    }

    return {};
  }
}
