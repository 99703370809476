import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DxDataGridModule, DxFormModule } from 'devextreme-angular';
import { DistributionListsGridComponent } from './ag-crm/email/grids/distribution-lists-grid/distribution-lists-grid.component';
import { DistributionListManagementComponent } from './ag-crm/email/details/distribution-list-management/distribution-list-management.component';
import { EmailTemplatesGridComponent } from './ag-crm/email/grids/email-templates-grid/email-templates-grid.component';
import { EmailTemplateManagementComponent } from './ag-crm/email/details/email-template-management/email-template-management.component';
import { HomeComponent } from './ag-crm/home/home.component';
import { ProfileComponent } from './ag-crm/profile/profile.component';
import { ConferencesComponent } from './ag-crm/registrations/conferences/conferences.component';
import { WebinarsComponent } from './ag-crm/registrations/webinars/webinars.component';
import { ContestsComponent } from './ag-crm/registrations/contests/contests.component';
import { LoginFormComponent } from './layouts/components/login-form/login-form.component';
import { RegistrationReportsComponent } from './ag-crm/reports/registration-reports/registration-reports.component';
import { TestComponent } from './ag-crm/contacts/test/test.component';
import { AppRoutes } from './app.model';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AuthGuard } from './shared/services/util/auth.guard';
import { ChristmasCardListComponent } from './ag-crm/reports/christmas-card-list/christmas-card-list.component';
import { AgCampaignsGuard } from './ag-campaigns/ag-campaigns.guard';
import { ConferenceRegistrationsGridComponent } from './ag-crm/registrations/conference-registration-grid/conference-registration-grid.component';
import { ConferencePostersListComponent } from './ag-crm/reports/conference-posters-list/conference-posters-list.component';
import { AgAdministrationGuard } from './admin-crm/admin-crm.guard';
import { CarriersGuard } from './ag-crm/carriers/carriers.guard';
import { RegistrantsGuard } from './ag-crm/registrants/registrants.guard';
import { ProspectsGuard } from './ag-crm/prospects/prospects.guard';
import { AgenciesGuard } from './ag-crm/agencies/agencies.guard';
import { AgentsGuard } from './ag-crm/agents/agents.guard';
import { DistributionListsGridGuard } from './ag-crm/email/grids/distribution-lists-grid/distribution-lists-grid.guard';
import { EmailTemplatesGridGuard } from './ag-crm/email/grids/email-templates-grid/email-templates-grid.guard';
import { ConferencesGuard } from './ag-crm/registrations/conferences/conferences.guard';
import { WebinarsGuard } from './ag-crm/registrations/webinars/webinars.guard';
import { ContestsGuard } from './ag-crm/registrations/contests/contests.guard';
import { RegistrationReportsGuard } from './ag-crm/reports/registration-reports/registration-reports.guard';
import { ChristmasCardListGuard } from './ag-crm/reports/christmas-card-list/christmas-card-list.guard';
import { AccessDeniedComponent } from './layouts/components/access-denied/access-denied.component';
import { AgNotificationsHubGuard } from './ag-notifications-hub/ag-notifications-hub.guard';
import { ConferenceRegistrationGuard } from './ag-crm/registrations/conference-registration-grid/conference-registration.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: AppRoutes.LoginForm },
  { path: AppRoutes.LoginForm, component: LoginFormComponent },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: AppRoutes.Agents,
        loadChildren: () => import('./ag-crm/agents/agents.module').then(mod => mod.AgentsModule),
        canActivate: [AgentsGuard],
      },
      {
        path: AppRoutes.Agencies,
        loadChildren: () => import('./ag-crm/agencies/agencies.module').then(mod => mod.AgenciesModule),
        canActivate: [AgenciesGuard],
      },
      {
        path: AppRoutes.Prospects,
        loadChildren: () => import('./ag-crm/prospects/prospects.module').then(mod => mod.ProspectsModule),
        canActivate: [ProspectsGuard],
      },
      {
        path: AppRoutes.Registrants,
        loadChildren: () => import('./ag-crm/registrants/registrants.module').then(mod => mod.RegistrantsModule),
        canActivate: [RegistrantsGuard],
      },
      {
        path: AppRoutes.Carriers,
        loadChildren: () => import('./ag-crm/carriers/carriers.module').then(mod => mod.CarriersModule),
        canActivate: [CarriersGuard],
      },
      {
        path: AppRoutes.NotificationsHub,
        loadChildren: () =>
          import('./ag-notifications-hub/ag-notifications-hub.module').then(mod => mod.AgNotificationsHubModule),
        canActivate: [AgNotificationsHubGuard],
      },
      {
        path: AppRoutes.Campaign,
        loadChildren: () => import('./ag-campaigns/ag-campaigns.module').then(mod => mod.CampaignsModule),
        canActivate: [AgCampaignsGuard],
      },
      {
        path: AppRoutes.Administration,
        loadChildren: () => import('./admin-crm/admin-crm.module').then(mod => mod.AdminCrmModule),
        canActivate: [AgAdministrationGuard],
      },

      { path: 'home', component: HomeComponent },
      { path: 'profile', component: ProfileComponent },

      {
        path: 'Distribution-Lists',
        component: DistributionListsGridComponent,
        canActivate: [DistributionListsGridGuard],
      },
      {
        path: 'Distribution-List-Management',
        component: DistributionListManagementComponent,
        canActivate: [DistributionListsGridGuard],
      },

      { path: 'Email-Templates', component: EmailTemplatesGridComponent, canActivate: [EmailTemplatesGridGuard] },
      {
        path: 'Email-Templates-Management',
        component: EmailTemplateManagementComponent,
        canActivate: [EmailTemplatesGridGuard],
      },

      { path: 'Events', component: ConferencesComponent, canActivate: [ConferencesGuard] },
      {
        path: 'conference-registrations',
        component: ConferenceRegistrationsGridComponent,
        canActivate: [ConferenceRegistrationGuard],
      },
      { path: 'Webinars', component: WebinarsComponent, canActivate: [WebinarsGuard] },
      { path: 'Contests', component: ContestsComponent, canActivate: [ContestsGuard] },

      {
        path: 'Registration-Reports',
        component: RegistrationReportsComponent,
        canActivate: [RegistrationReportsGuard],
      },
      { path: 'Christmas-Card-List', component: ChristmasCardListComponent, canActivate: [ChristmasCardListGuard] },
      { path: 'Conference-Posters-List', component: ConferencePostersListComponent, canActivate: [ConferencesGuard] },

      { path: 'Test', component: TestComponent },
      { path: 'access-denied', component: AccessDeniedComponent }, // Add this line
    ],
  },

  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), DxDataGridModule, DxFormModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
