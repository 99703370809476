import { Component, HostBinding, ViewChild } from '@angular/core';
import {
  BaseModelKeys,
  Conference,
  ConferenceKeys,
  Constants,
  DX_USD_CURRENCY_FORMAT,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  Registrant,
  RegistrantKeys,
} from 'ag-common-lib/public-api';
import { ConferenceService } from 'ag-common-svc/lib/services/conference.service';
import { ConferenceModalComponent } from './modals/conference-modal/conference-modal.component';
import { CONFERENCES_TITLE_LIST } from './config/conferences.config';
import { AttendeesListModalComponent } from './modals/attendees-list-modal/attendees-list-modal.component';
import { Router } from '@angular/router';
import { RowRemovingEvent } from 'devextreme/ui/data_grid';
import { ConferenceExcursionStatisticModalComponent } from './modals/conference-excursion-statistic-modal/conference-excursion-statistic-modal.component';
import { ConferenceTransactionsModalComponent } from './modals/conference-transactions-modal/conference-transactions-modal.component';
import { map, shareReplay } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { AuthService } from 'ag-common-svc/public-api';
import { HasPermissionPipe } from 'ag-common-svc/shared/pipes/has-permission.pipe';

@Component({
  selector: 'app-conferences',
  templateUrl: './conferences.component.html',
  styleUrls: ['./conferences.component.scss'],
  providers: [HasPermissionPipe],
})
export class ConferencesComponent {
  @HostBinding('class') className = 'conferences grid';
  @ViewChild('conferenceModalRef') conferenceModalComponent: ConferenceModalComponent;
  @ViewChild('attendeesModalRef') attendeesModalComponent: AttendeesListModalComponent;
  @ViewChild('conferenceExcursionStatisticModalRef')
  conferenceExcursionStatisticModalComponent: ConferenceExcursionStatisticModalComponent;
  @ViewChild('conferenceTransactionsModalRef')
  conferenceTransactionsModalComponent: ConferenceTransactionsModalComponent;

  registrants: Promise<Registrant[]>;
  conferences$: Observable<Conference[]> = combineLatest({
    conferences: this.conferenceService.getList(),
    loggedInAgent: this.authService.loggedInAgent$,
    hasEventListPermission: this.hasPermissionPipe.transform(Entity.conferenceList, EntityPermissionActivityKeys.read),
    hasEventLisAdminPermission: this.hasPermissionPipe.transform(
      Entity.conferenceListAdmin,
      EntityPermissionActivityKeys.read,
    ),
  }).pipe(
    map(({ conferences, loggedInAgent, hasEventListPermission, hasEventLisAdminPermission }) => {
      if (hasEventLisAdminPermission) {
        return conferences;
      }

      if (hasEventListPermission) {
        const agentDbId = loggedInAgent?.[BaseModelKeys.dbId];
        return conferences?.filter(conference => {
          const createdByAgentDbId = [conference?.[BaseModelKeys.createdByAgentDbId]];
          const permissionConfigurationIds = conference?.[ConferenceKeys.permissionConfigurationIds] ?? [];
          const permissionIds = new Set(createdByAgentDbId.concat(permissionConfigurationIds));
          return permissionIds?.has(agentDbId);
        });
      }
      return [];
    }),
    shareReplay(1),
  );

  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly emptyMessage: string = 'No Events Currently Exist';
  protected readonly titleList = CONFERENCES_TITLE_LIST;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;

  constructor(
    private conferenceService: ConferenceService,
    private router: Router,
    private authService: AuthService,
    private hasPermissionPipe: HasPermissionPipe,
  ) {}

  protected onRowRemoving = (e: RowRemovingEvent) => {
    e.cancel = this.conferenceService.delete(e.data?.[BaseModelKeys.dbId]);
  };

  protected addConference = (): void => {
    this.conferenceModalComponent.showModal();
  };

  protected editConference = ({ row: { data } }): void => {
    this.conferenceModalComponent.showModal(data);
  };

  protected onRowDblClick({ data }): void {
    this.attendeesModalComponent.showModal(data);
  }

  protected showRegistrantsConfiguration({ row: { data } }): void {
    this.attendeesModalComponent.showModal(data);
  }

  protected showConferenceTasks = ({ row: { data } }): void => {
    this.router.navigate(['/conference-registrations'], { queryParams: { id: data.dbId } });
  };

  protected showSeatRemainingList = ({ row: { data } }): void => {
    this.conferenceExcursionStatisticModalComponent.showModal(data);
  };

  protected showTransactionsModal = ({ row: { data } }): void => {
    this.conferenceTransactionsModalComponent.showModal(data);
  };

  isSeatsRemainingVisible = ({ row: { data } }) => data?.[ConferenceKeys.excursionsEnabled];
}
