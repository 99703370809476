import { BaseModel } from '../base.model';

export enum DynamicListsGroupKeys {
    description = 'description',
    title = 'title',
    deniedRecipients = 'deniedRecipients',
    segmentsCollection = 'segmentsCollection',
}

export class DynamicListsGroup extends BaseModel {
    [DynamicListsGroupKeys.title]: string;
    [DynamicListsGroupKeys.description]?: string;
    [DynamicListsGroupKeys.deniedRecipients]: string[] = [];
    [DynamicListsGroupKeys.segmentsCollection]: string;
}
